.date-container {
  width: 100% !important;

  .event-date {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    padding: 4px 8px;
  }
}

.remove-button-container {
  align-self: center;

  .remove-button {
    float: right;
  }
}
