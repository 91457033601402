.sponsorships {
  width: auto;
  padding: 2rem;
  margin: 0 auto;

  .leaderboard {
    margin-bottom: 1.5rem;
  }

  &.mobile {
    padding: 1rem;

    .leaderboard {
      margin-bottom: 0.5rem;
    }

    .paper {
      .tab-label {
        font-size: 10px;
      }
    }
  }

  .page-header {
    margin-bottom: 0;
  }

  .paper {
    padding: 2rem;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;

    .tab-label {
      font-size: 14px;
    }

    a:hover {
      color: unset;
    }
  }

  .sponsorship-form {
    padding-top: 1rem;

    .contact_me_by_fax_only {
      display: none !important;
    }

    .alert {
      margin-top: 0.5rem;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
    }
  }
}
