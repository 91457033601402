$list-width-small: 30rem;
$tablet-event-width: 60rem;

.events {
  min-height: 1050px;
  height: 100%;
  display: grid;
  grid-template-areas:
    'ads ads'
    'list event';
  grid-template-rows: 90px auto;
  grid-template-columns: 1fr;
  padding: 1.5rem;
  grid-gap: 1rem;

  &.small {
    grid-template-columns: $list-width-small auto;
  }

  &.tablet {
    display: flex;
    flex-direction: column;
    min-width: 30rem;
    max-width: $tablet-event-width;
    margin-left: auto;
    margin-right: auto;
  }

  &.mobile {
    padding: 1rem;
    display: block;
    grid-template-columns: 1fr;
  }

  .leaderboard {
    margin-bottom: 0.5rem;
    grid-area: ads;
  }

  .events-list {
    grid-area: list;
  }

  .details {
    grid-area: event;
    min-width: 0;
  }
}
