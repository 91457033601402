.event-ad {
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    cursor: pointer;
    display: flex;
  }

  &.desktop,
  .tablet {
    a,
    img {
      max-width: 728px;
      max-height: 90px;
    }
  }

  &.mobile {
    a,
    img {
      max-width: 300px;
      max-height: 50px;
    }
  }

  .ad {
    width: 100%;
  }
}
