.event-list {
  .leaderboard {
    margin-bottom: 1rem;
  }

  .list-header {
    display: flex;
    align-items: center;

    .list-title {
      flex-grow: 1;
      color: white;
    }

    .filter-container {
      display: inline-flex;
      cursor: pointer;

      .filter-text {
        margin-right: 0.5rem;
        color: white;
      }

      .filter-icon {
        align-self: center;
        color: white;
      }
    }
  }

  .divider {
    margin-top: 0.25rem !important;
  }

  .advertisement {
    width: 100% !important;
  }

  .events-container {
    display: grid;
    grid-template-columns: auto;

    &.desktop {
      grid-template-columns: 482px auto;
    }

    .left-column {
      margin-right: 1rem;

      .filters-container {
        padding: 1rem;
        height: fit-content;
        margin-bottom: 1rem;
        background-color: #ffffffe0 !important;
      }
    }

    .no-events,
    .location-error {
      background-color: #ffffffe0 !important;
      height: fit-content;

      .MuiCardContent-root {
        padding-bottom: 16px !important;
      }

      button {
        padding: 0 !important;
      }
    }

    .list-container {
      position: relative;
      //overflow-y: scroll;

      .list-side-ad {
        margin-bottom: 1rem;
      }

      .list {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .list-title {
          flex: grow;
        }
      }

      .additional-events-title {
        text-align: center;
        margin-top: 1rem;
        color: white;
      }

      .load-more-items {
        text-align: center;
      }
    }
  }
}

.drawer {
  .MuiPaper-root {
    .drawer-content {
      padding: 2rem;

      .drawer-header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
}
