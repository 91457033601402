$header-height: 80px;

.header {
  display: flex;
  justify-content: space-between;
  background-color: white;
  max-height: $header-height;

  &.mobile {
    justify-content: space-around;
    height: 60px;
  }

  svg {
    height: 2rem;
    width: 2rem;
  }

  .logo {
    max-width: 543px;
    justify-self: center;
    padding: 0.5rem 1rem;

    &.mobile {
      object-fit: contain;
      width: 85%;
      padding-left: 0;
    }
  }

  .logo:hover {
    cursor: pointer;
  }

  .background {
    height: $header-height;
  }
}

.nav-drawer {
  .MuiPaper-root {
    .drawer-content {
      width: 15rem;
      display: flex;
      flex-direction: column;

      a {
        width: 100%;
        padding-top: 1.2rem;
        padding-bottom: 1rem;
      }

      a:hover {
        color: #d91f2c;
      }

      .separator {
        border-bottom: 1px lightgrey solid;
        margin: 0 1rem;
      }
    }
  }
}
