.contact-us {
  padding-top: 1rem;

  .contact_me_by_fax_only {
    display: none !important;
  }

  .alert {
    margin-top: 0.5rem;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
}
