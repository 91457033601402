.nav-bar {
  flex-grow: 1;
  background-color: #f4f7f3;

  .MuiToolbar-regular {
    min-height: 40px !important;
  }

  a {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  a:hover {
    color: #d91f2c;
  }

  .separator {
    height: 2rem;
    border-right: 1px darkgray solid;
  }
}
