.footer {
  background-color: #424242;
  height: 11.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 2rem;
  color: white;

  .footer-column {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin-bottom: 0.5rem;
    }

    a {
      padding-top: 0;
      color: #4183c4;
    }

    button {
      padding-top: 0;
      color: #4183c4;
    }

    .mobile {
      .MuiButton-startIcon {
        margin: 0;
      }
    }
  }
}
