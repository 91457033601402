.layout {
  width: auto;
  padding: 2rem;
  margin: 0 auto;

  .leaderboard {
    margin-bottom: 1.5rem;
  }

  .contact_me_by_fax_only {
    display: none !important;
  }

  &.mobile {
    padding: 1rem;

    .leaderboard {
      margin-bottom: 0.5rem;
    }
  }

  .paper {
    padding: 2rem;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;

    .page-header {
      &.mobile {
        margin-bottom: 1rem;
      }
    }

    &.mobile {
      .bulk-events-alert {
        margin: 0 0 1rem;
      }
    }

    .bulk-events-alert {
      margin: 1rem 0 0;
    }

    .stepper {
      padding: 24px 0 !important;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
    }

    .mobile-stepper {
      margin-top: 1rem;
    }
  }

  .text-link-container {
    margin-top: 0.5rem;

    h6 {
      display: inline;
    }

    a {
      margin-left: 0.25rem;
      display: inline-block;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .form-submission-alert {
    margin-top: 1rem;

    .text-link-container {
      margin-top: 0;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
}
