.leaderboard {
  width: 100%;
  text-align: center;

  &.desktop {
    img {
      max-width: 970px;
      max-height: 90px;
    }
  }

  &.tablet,
  .mobile {
    img {
      max-width: 728px;
      max-height: 90px;
    }
  }

  a {
    cursor: pointer;
  }

  .ad {
    width: 100%;
  }
}
