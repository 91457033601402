.report-dialog {
  .dialog-title {
    padding-bottom: 0 !important;
  }

  .contact_me_by_fax_only {
    display: none !important;
  }

  .hide {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}
