.upcoming-events {
  max-width: calc(100vw - 4rem);

  &.mobile {
    max-width: calc(100vw - 6rem);
  }

  .title {
    text-align: center;
    padding-bottom: 0.5rem;
  }

  .event-item {
    margin-bottom: 0;
  }

  .no-events {
    .MuiCardContent-root:last-child {
      padding-bottom: 16px !important;
    }
  }

  .loading {
    display: flex;
    justify-content: center;
  }
}
