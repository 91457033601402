$navbar-height: 40px;

.App {
  background: url('../../images/background.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #686f78;
  grid-template-columns: 1fr;
  grid-template-rows: auto $navbar-height auto;
  grid-template-areas: 'header' 'navbar' 'workspace' 'footer';

  &.mobile {
    .workspace {
      min-height: calc(100vh - 60px - 11.25rem);
    }
  }

  .header {
    grid-area: header;
  }

  .nav-bar {
    grid-area: navbar;
  }

  .workspace {
    grid-area: workspace;
    width: 100%;
    max-width: 1280px;
    min-height: calc(100vh - 80px - 40px - 11.25rem);
    margin-left: auto;
    margin-right: auto;
  }

  .footer {
    grid-area: footer;
  }

  .MuiAlert-message {
    .snackbar {
      display: inline-flex;
      align-items: center;
    }
  }

  .MuiDivider-root {
    margin: 1rem 0;
  }
}

.loading {
  display: flex;
  justify-content: center;
}
