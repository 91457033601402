.list-filter-form {
  .distance-slider-container {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .my-location-label {
    padding-top: 0.6rem;
  }

  .province {
    width: 75%;
  }

  .event-types {
    width: 100%;
    max-width: 480px;
  }

  .date-filter-to {
    padding-top: 0.25rem;
    text-align: center;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }

  .MuiDivider-root {
    margin: 1rem 0;
  }

  a {
    margin-right: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
