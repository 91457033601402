.list-side-ad {
  width: 100%;
  text-align: center;
  height: 60px;
  margin-bottom: 1rem;

  img {
    max-width: 468px;
    height: 60px;
  }

  a {
    cursor: pointer;
  }

  .ad {
    width: 100%;
  }
}
