.event-item-link:hover {
  text-decoration: none !important;
}

.event-item-link {
  display: flex;
  flex-flow: row nowrap;

  .cancelled-stamp-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;

    .cancelled-stamp {
      width: 100%;
      height: auto;
      transform: rotate(-10deg);
      align-self: center;
    }
  }

  .event-item {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    cursor: pointer;
    background-color: #ffffffe0 !important;

    &.selected {
      background-color: white !important;
    }

    &:hover {
      background-color: white !important;
    }

    .event-name {
      margin-bottom: 0.2rem;
    }

    .list-item-info {
      display: flex;
      white-space: pre-wrap;
      align-items: center;

      svg {
        margin-right: 0.5rem;
        height: 1rem;
        width: 1rem;
      }
    }

    .event-list-date {
      padding-right: 0;
      text-align: center;
    }

    .event-list-type {
      margin-right: 0.5rem;
      margin-top: 4px;
    }

    .MuiCardContent-root:last-child {
      padding-bottom: 20px;
    }
  }

  .dark-red {
    background-color: #c0392b;
  }

  .red {
    background-color: #e74c3c;
  }

  .purple {
    background-color: #9b59b6;
  }

  .deep-purple {
    background-color: #8e44ad;
  }

  .dark-blue {
    background-color: #2980b9;
  }

  .blue {
    background-color: #3498db;
  }

  .teal {
    background-color: #1abc9c;
  }

  .dark-teal {
    background-color: #16a085;
  }

  .dark-green {
    background-color: #16a085;
  }

  .green {
    background-color: #2ecc71;
  }

  .yellow {
    background-color: #f1c40f;
  }

  .amber {
    background-color: #f39c12;
  }

  .orange {
    background-color: #e67e22;
  }

  .deep-orange {
    background-color: #d35400;
  }

  .grey {
    background-color: #95a5a6;
  }

  .dark-grey {
    background-color: #7f8c8d;
  }

  .blue-grey {
    background-color: #34495e;
  }
}
