.event-paper {
  padding: 2rem;
  max-width: 700rem;
  background-color: #ffffffe0 !important;

  .info {
    display: flex;
    margin-top: 1rem;
    white-space: pre-wrap;

    .race-monitor-icon {
      margin-right: 1rem;
      text-align: center;
      writing-mode: vertical-rl;
    }

    &.race-monitor {
      flex-direction: column;
      align-items: center;

      .race-monitor-logo {
        margin-top: 0.5rem;
        height: 5rem;
      }
    }
  }

  .action {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &.underlined {
      text-decoration: underline;
    }
  }

  .event-header {
    display: flex;

    .event-date {
      text-align: center;
      align-self: center;
      margin-right: 1.5rem;
    }

    .event-title {
      align-self: center;
    }
  }

  .event-images {
    display: flex;
    justify-content: center;
    border: 1px #ababab solid;
    margin-bottom: 1rem;
    position: relative;

    .cancelled-stamp-container {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;

      .cancelled-stamp {
        width: 100%;
        height: auto;
        transform: rotate(-10deg);
        align-self: center;
      }
    }
  }

  .event-actions {
    display: flex;
    justify-content: space-evenly;
    padding: 0.5rem 0;
    margin: 1rem 0;
  }

  .event-details {
    padding: 0.5rem 0;

    .event-ad {
      margin-bottom: 1rem;
    }

    svg {
      margin-right: 1rem;
    }

    .center {
      align-items: center;
    }
  }

  .event-ads {
    padding-top: 0.5rem;
  }
}

.loading {
  display: grid;
  margin-top: 3rem;
}
