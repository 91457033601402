.location-form {
  .no-search-result {
    min-width: 350px;
  }

  .add-new-location {
    margin: 1rem 1rem 0;

    button {
      margin-left: 1rem;
    }
  }

  .change-location {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row-reverse;

    a {
      cursor: pointer;
    }
  }

  .search-again {
    margin-top: 0.5rem;

    a {
      cursor: pointer;
    }
  }

  .selected-result {
    margin-top: 16px;
  }
}
